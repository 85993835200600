import EventEmitter from "events"
import { Vec2, mult, subtract } from "./util"

export interface MouseEmitter {
    on(key: 'move', h: (delta: Vec2) => void): this
}

/**
 * Simplified mouse event handling, capturing the distance the mouse is from the center of the screen
 */
export class MouseEmitter extends EventEmitter {

    constructor(element: Element) {

        super()

        let locked = false

        window.addEventListener('click', () => {
            element.requestPointerLock()
        })

        document.addEventListener('pointerlockchange', () => {
            if (document.pointerLockElement === element) {
                locked = true
            } else {
                locked = false
            }
        })

        window.addEventListener('mousemove', e => {

            if (locked) {
                this.emit('move', [e.movementX, e.movementY])
            } 

        })

    }

}