import * as THREE from 'three'
import { Objector } from '.'
import { mod } from '../util'

const JUMP_HEIGHT = 4
const JUMP_TIME = 500_000

export const createObamaCube: Objector = (_, txts) => {

    
    const geometry = new THREE.BoxGeometry( 1, 1, 1 )
    const material = new THREE.MeshBasicMaterial({
        map: txts["oboba.png"]
    })
    const cube = new THREE.Mesh( geometry, material )
    cube.position.x = 10

    return [cube, timeOffset => {
        cube.position.y = JUMP_HEIGHT * (
            JUMP_TIME - (
                mod(
                    timeOffset, 
                    2 * Math.sqrt(JUMP_TIME)
                ) - Math.sqrt(JUMP_TIME)
            ) ** 2
        ) / JUMP_TIME 
    }]

}