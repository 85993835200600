import * as THREE from 'three'

/**
 * Add any resources you want to load here
 * 
 * You will then be able to reference them in `initialise_scene`
 * e.g. as `"RESOURCES.vert_shader"`
 */
const RESOURCE_SCHEMA = [
    // format is:
    // ["name", "path-to-resource"]
    ["vert_shader", "shaders/default.vert"],
    ["frag_shader", "shaders/default.frag"],
    ["vert_basic", "shaders/basic.vert"],
    ["color", "shaders/color.frag"],
    ["warp", "shaders/warp.frag"],
    ["scanline", "shaders/scanline.frag"],
    ["bloom", "shaders/bloom.frag"],
    ["tv", "shaders/tv.frag"],
    ["antialias", "shaders/antialias.frag"],
]

export type Resources = { [shader: string]: string }

/** 
 * Asynchronously load resources
 *
 * You shouldn't need to change this - you can add
 * more resources by changing `RESOURCES` above 
 */
export async function loadResources(): Promise<Resources> {

    const res = await Promise.all(
        RESOURCE_SCHEMA.map(
            r => fetch(r[1]).then(
                res => res.text()
            )   
        )
    )
    
    let resources: Resources = {}

    for (let i in RESOURCE_SCHEMA) resources[RESOURCE_SCHEMA[i][0]] = res[i]

    return resources

}

/**
 * Textures in public/textures/ to be loaded
 */
const TEXTURES = [
    "oboba.png",
    "april.png"
]

export type Textures = { [shader: string]: THREE.Texture }

/**
 * Returns an object of all textures in public/textures/ as `THREE.Texture`s
 */
export async function loadTextures(): Promise<Textures> {

    const loader = new THREE.TextureLoader()

    const ts = await Promise.all(
        TEXTURES.map(t => {
            const location = `textures/${t}`
            return new Promise<[string, THREE.Texture]>((res, rej) => {
                loader.load(location, texture => res([t, texture]), undefined, rej)
            })
        })
    )

    const textures: Textures = {}

    for (const [name, t] of ts) textures[name] = t

    return textures

}