import { Resources, Textures } from "./resources"
import * as THREE from 'three'
import { getRenderer, getRendererBasic } from "./renderer"
import { createAprilCube } from "./objects/aprilCube"
import { createLightCube } from "./objects/lightCube"
import { createObamaCube } from "./objects/obamaCube"
import { createAxes } from "./objects/axes"
import { createCamera } from "./objects/camera"

/**
 * Create the scene
 */
export async function initialiseScene(ress: Resources, txts: Textures) {

    // You can use your loaded resources here; resources.vert_shader will
    // be the content of the vert_shader file listed in `RESOURCES`, for
    // example

    // Set up the key parts of your renderer: a camera, a scene and the renderer
    const scene = new THREE.Scene()

    const rendererBasic = getRendererBasic()
    const [camera, updateCamera] = createCamera(rendererBasic.domElement)
    const renderer = getRenderer(rendererBasic, scene, camera, ress)

    // Add things to your scene here

    const axes = createAxes()
    const [lightCube, updateLightCube] = createLightCube(ress, txts)
    const [obamaCube, updateObamaCube] = createObamaCube(ress, txts)
    const [aprilCube, updateAprilCube] = createAprilCube(ress, txts)

    axes.forEach(axis => scene.add(axis))
    scene.add(lightCube)
    scene.add(obamaCube)
    scene.add(aprilCube)

    console.log("Rendering")

    const startTime = new Date()

    // Your animation loop, which will run repeatedly and renders a new frame each time
    while (true) {

        // Calculate time since beginning, used for animations
        const currTime = new Date()
        const timeOffset = currTime.getTime() - startTime.getTime()

        updateCamera()
        updateLightCube(timeOffset)
        updateObamaCube(timeOffset)
        updateAprilCube(timeOffset)

        // Render scene
        renderer.render(timeOffset)
        // Wait for browser to prompt next frame
        await new Promise(res => requestAnimationFrame(res))
    }

}
