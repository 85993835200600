import * as THREE from 'three'
import { Objector } from '.'
import { mod } from '../util'

const ROLL_TIME = 5_000

export const createAprilCube: Objector = (_, txts) => {

    const geometry = new THREE.BoxGeometry( 1, 1, 1 )
    const material = new THREE.MeshBasicMaterial({
        map: txts["april.png"]
    })
    const cube = new THREE.Mesh( geometry, material )
    cube.position.z = 10

    return [cube, timeOffset => {
        const sinTime = Math.sin(timeOffset / ROLL_TIME)*Math.PI*10
        cube.position.y = (1 + Math.sin(mod(sinTime, Math.PI))) / 2
        cube.position.z = 15 - Math.cos(mod(sinTime, Math.PI)) + 2 * Math.floor(sinTime / Math.PI)
        cube.position.x = -10
        cube.rotation.x = sinTime
    }]

}