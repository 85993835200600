import * as THREE from 'three'
import { MovingBody, setCameraPos } from '../body'
import { KeySet } from '../keys'
import { MouseEmitter } from '../mouse'

export const createCamera = (element: Element): [THREE.Camera, () => void] => {

    const camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 1000 )

    const cameraBody = new MovingBody()
    cameraBody.pos = [0, 0, -8]
    cameraBody.yaw = .3
    cameraBody.pitch = 0

    const keys = new KeySet()

    const mouse = new MouseEmitter(element)
    mouse.on('move', ([x, y]) => {
        const DIFF = -250
        cameraBody.pushAngle(x / DIFF, y / DIFF)
    })

    return [camera, () => {
        if (keys.isDown('KeyA')) cameraBody.pushLeft()
        if (keys.isDown('KeyW')) cameraBody.pushForward()
        if (keys.isDown('KeyS')) cameraBody.pushBackward()
        if (keys.isDown('KeyD')) cameraBody.pushRight()
        if (keys.isDown('Space')) cameraBody.pushUp()
        if (keys.isDown('ShiftLeft')) cameraBody.pushDown()
        cameraBody.step()

        setCameraPos(camera, cameraBody)
    }]

}