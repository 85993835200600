import * as THREE from 'three'

export const createAxes = (): THREE.Line[] => {

    const lines: THREE.Line[] = []

    const addLine = (color: number, points: THREE.Vector3[]) => {
        const material = new THREE.LineBasicMaterial({ color })
        const geometry = new THREE.BufferGeometry().setFromPoints(points)
        const line = new THREE.Line(geometry, material)
        lines.push(line)
    }
    
    const x = [new THREE.Vector3(0,0,0), new THREE.Vector3(5, 0, 0)]
    const y = [new THREE.Vector3(0,0,0), new THREE.Vector3(0, 5, 0)]
    const z = [new THREE.Vector3(0,0,0), new THREE.Vector3(0, 0, 5)]

    addLine(0xff0000, x)
    addLine(0x00ff00, y)
    addLine(0x0000ff, z)

    return lines

}