import * as THREE from 'three'
import { Objector } from '.'

export const createLightCube: Objector = (ress) => {

    const geometry = new THREE.BoxGeometry(1, 1, 1)
    const material = new THREE.ShaderMaterial({

        uniforms: {
    
            time: { value: 1.0 },
            resolution: { value: new THREE.Vector2() }
    
        },
        vertexShader: ress.vert_shader,
        fragmentShader: ress.frag_shader
    
    })
    const cube = new THREE.Mesh( geometry, material )

    return [cube, () => {
        cube.rotation.x += 0.01;
        cube.rotation.y += 0.01;
    }]

}