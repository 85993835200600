import { loadResources, loadTextures } from "./resources"
import { initialiseScene } from "./render"

/**
 * Load the resources and then create the scene when resources are loaded
 */
(async () => {
    const resources = await loadResources()
    const textures = await loadTextures()
    console.log("Loaded resources")
    initialiseScene(resources, textures)
})()
