import * as THREE from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js'
import { Resources } from './resources'

/**
 * Create simple renderer and append to DOM
 */
export const getRendererBasic = () => {

    // Clear DOM tree
    
    for (const child of document.body.children) {
        document.body.removeChild(child)
    }

    // Append new canvas

    const renderer = new THREE.WebGLRenderer({ antialias: true })
    renderer.setSize(window.innerWidth, window.innerHeight)
    document.body.appendChild(renderer.domElement)
    return renderer

}

/**
 * Create renderer with post-processing
 */
export const getRenderer = (renderer: THREE.WebGLRenderer, scene: THREE.Scene, camera: THREE.Camera, ress: Resources): EffectComposer => {

    renderer.setClearColor("#eeffee");

    const composer = new EffectComposer(renderer)

    // shaders

    const renderPass = new RenderPass(scene, camera)
    const fxaaPass = new ShaderPass(FXAAShader)

    const addShader = (name: string) => {
        const pass = new ShaderPass({

            uniforms: {
                time: { value: 1.0 },
                'tDiffuse': { value: null },
                resolution: { value: new THREE.Vector2(window.innerWidth, window.innerHeight) },
            },
            vertexShader: ress.vert_basic,
            fragmentShader: ress[name]
        
        })
        composer.addPass(pass)
    }

    composer.addPass(renderPass)
    addShader("color")
    addShader("warp")
    addShader("bloom")
    addShader("scanline")
    addShader("tv")
    addShader("bloom")
    composer.addPass(fxaaPass)

    return composer

}