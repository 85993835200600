import { add, clamp, cross, len, mod, mult, setLen, Vec3 } from "./util"

/**
 * A basic physics engine, allows movement around a 3D environment
 */
export class MovingBody {

    pos: Vec3 = [0,0,0]
    v: Vec3 = [0,0,0]
    a: Vec3 = [0,0,0]

    pitch: number = 0
    yaw: number = 0
    get cameraAngle(): Vec3 {
        return [
            Math.sin(this.pitch)*Math.cos(this.yaw),
            Math.sin(this.yaw),
            Math.cos(this.pitch)*Math.cos(this.yaw)
        ]
    }
    get bodyAngle(): Vec3 {
        return [
            Math.sin(this.pitch),
            0,
            Math.cos(this.pitch),
        ]
    }

    friction: number = 0.05
    maxV: number = 80
    lastStep: Date = new Date()
    pushSize: number = 40
    //pushAngleSize: number = 5

    setAngle(pitch: number, yaw: number) {
        this.pitch = mod(pitch, Math.PI*2) 
        this.yaw = clamp(yaw, -Math.PI, Math.PI)
    }

    pushAngle(pitch: number, yaw: number) {
        this.pitch = mod(this.pitch + pitch, Math.PI*2) 
        this.yaw = clamp(this.yaw + yaw, 1e-10 - Math.PI / 2, Math.PI / 2 - 1e-10)
    }

    push(dir: Vec3) {
        this.a = add(this.a, dir)
    }
    pushUp() {
        this.push([0, this.pushSize, 0])
    }
    pushDown() {
        this.push([0, -this.pushSize, 0])
    }
    pushForward() {
        this.push(setLen(this.bodyAngle, this.pushSize))
    }
    pushBackward() {
        this.push(setLen(this.bodyAngle, -this.pushSize))
    }
    pushLeft() {
        this.push(setLen(cross([0, 1, 0], this.bodyAngle), this.pushSize))
    }
    pushRight() {
        this.push(setLen(cross(this.bodyAngle, [0, 1, 0]), this.pushSize))
    }

    /**
     * Calculate next position of the body.
     */
    step() {
        // Find step size
        const now = new Date()
        const deltaT = (now.getTime() - this.lastStep.getTime()) / 1_000
        this.lastStep = now

        // Apply acceleration
        this.v = add(this.v, mult(this.a, deltaT))
        this.a = [0,0,0]

        // Apply friction and clamp max speed
        this.v = mult(this.v, 1 - this.friction)
        const vAbs = len(this.v)
        this.v = vAbs > this.maxV ? setLen(this.v, this.maxV) : this.v

        // Apply velocity
        this.pos = add(this.pos, mult(this.v, deltaT))
    }

}

export const setCameraPos = (camera: THREE.PerspectiveCamera, body: MovingBody) => {
    camera.position.set(...body.pos)
    camera.lookAt(...add(body.cameraAngle, body.pos))
}