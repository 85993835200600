
/**
 * Keeping track of live keypresses - at any point, `keySet.isDown(x)` is only valid
 * if the key `x` is currently being pressed.
 */
export class KeySet {

    set = new Set()

    constructor() {

        document.addEventListener('keydown', e => {
            this.set.add(e.code)
        })
        document.addEventListener('keyup', e => {
            this.set.delete(e.code)
        })

    }

    isDown(code: string): boolean {
        return this.set.has(code)
    }

}