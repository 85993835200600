import _ from 'lodash'

/**
 * Common graphics-y functions
 */

export type Vec2 = [number, number]
export type Vec3 = [number, number, number]

export const add = <T extends number[]>(a: T, b: T): T => _.zipWith(a, b, (x, y) => x + y) as T
export const subtract = <T extends number[]>(a: T, b: T): T => _.zipWith(a, b, (x, y) => x - y) as T
export const mult = <T extends number[]>(a: T, b: number): T => a.map(x => x*b) as T
export const minus = <T extends number[]>(a: T): T => mult(a, -1)
export const dot = <T extends number[]>(a: T, b: T): number => _.sum(_.zipWith(a, b, (x, y) => x * y))
export const cross = (a: Vec3, b: Vec3): Vec3 => [
    a[1]*b[2] - a[2]*b[1],
    a[2]*b[0] - a[0]*b[2],
    a[0]*b[1] - a[1]*b[0],
]
export const len = <T extends number[]>(a: T): number => Math.sqrt(_.sum(a.map(x => x**2)))
export const setLen = <T extends number[]>(a: T, l: number): T => {
    const oldLen = len(a)
    return mult(a, l / oldLen)
}

export const mod = (x: number, m: number): number => ((x%m)+m)%m
export const clamp = (x: number, lB: number, uB: number): number => x >= uB ? uB : x <= lB ? lB : x